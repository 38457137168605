.expenses{
    background-color: #F7F8FA;
    width: 100%;
}

.card-container {
    width: 100%;
    text-align: left;
    padding: 20px;
}

.expenses-card {
    display: inline-block;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 1rem;
    background-color: #ffffff;
    height: 160px;
    margin: 5px;
    width: 100%;
    padding: 12px;
}

.expenses-card-profile {
    padding: 4px;
    height: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.expenses-card-info {
    text-align: left;
    height: 150px; 
    padding: 0;
    display: flex;
    flex-direction: column;
    padding: 12px;
}

.expenses-item {
    flex: auto;
}

.expenses-user {
}

.expenses-user-img {
    height: 100%;
    width: 100%;
}

.expenses-amount {
    font-size: 16pt;
    font-weight: 600;
}

.expenses-category {
    font-size: 8pt;
}

.expenses-merchant {
    font-size: 12pt;
}

.expenses-description {
    font-size: 10pt;
    overflow: wrap;
    font-weight: 200;
}

.expenses-timestamp {
    font-size: 6pt;
    text-align: right;
}

/* EditExpense.js */

.edit-expense {
    background-color: rgb(0,0,0,0.4);
    z-index: 1;
}

.edit-expense-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    margin: 15% auto;
    padding: 40px;
    max-width: 80%;
    min-height: 50vh;
}
.edit-expense-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* EditExpense.js */